.App {
	// text-align: center;
	// border: 10px solid red;
	// min-height: 100vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
  
	#load {
		text-align:center;
		display: inline-block;
		cursor:pointer;
		background-color: white;
		color: black;
		padding: 20px;
		font-size: 20px;
		width: 400px;
		margin: 20px;
		border: 4px solid transparent;
		transition: all 200ms ease-in-out;

		&:hover {
			background-color:black;
			color: white;
			border-color: white;
		}

		@media screen and (max-width: 450px) {
			max-width: 101px;
		}
	}
}


