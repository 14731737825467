.movie-modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	padding: 30px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	z-index: 10;
	animation-name: opacity;
	animation-duration: 500ms;
}
.movie-modal {
	background-color: black;
	width: 700px;
	height: 500px;
	margin: 0 auto;
	position: relative;
	box-shadow: 0 0 100px rgba(255, 255, 255, 0.2);
}

.movie-modal button {
	border: 2px solid #fff;
	outline: none;
	background-color: black;
	color: #fff;
	font-size: 24px;
	position: absolute;
	top: -20px;
	right: -20px;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
.movie-modal button:hover {
	background-color: rgb(43, 42, 42);
}

@keyframes example {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
