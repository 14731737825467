.movie-list-outer {
	position: relative;
	top: 100px;
	@media screen and (max-width: 450px) {
		top: 120px;
	}
	.movie-list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		flex-grow: 1;
		overflow-y: auto;
		padding: 0 20px 20px;

		&:hover .movie-item {
			// opacity: 0.8;
			// filter: grayscale(70%);
			transition: all 300ms ease-in-out;
			&:hover {
				// opacity: 1;
				// filter: grayscale(0);
				img {
					transform: scale(1.15);
					z-index: 4;
				}
			}
		}
	}
}
