.movie-detail
{
	background-color: #000;
	position:fixed;
	top: 0; left: 0;
	width: 100vw;
	height: 100vh;
	animation-name: opacity;
  	animation-duration: 500ms;
	z-index: 5;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	
	h1 {margin-bottom: 50px;}
	
	.movie-detail-media {
		display:flex;
		gap: 50px;
		padding: 50px;

		.movie-detail-image {
			flex-basis: 50%;
			text-align: right;
			@media screen and (max-width: 900px) {

				text-align: left;
			}
			img {
				height: auto;
				max-width: 100%;
			}
		}

		.movie-detail-content {
			flex-basis: 50%;
			padding-right: 110px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@media screen and (max-width: 900px) {
				padding:10px;
			}
			.movie-detail-torrents-info
			{
				display:flex;
				flex-wrap:wrap;
				gap: 20px;
			
				.movie-detail-torrent
				{
					text-align: left;
					font-size:18px;
					

					@media screen and (max-width: 900px) {

						padding:0;
					}

					.buttons {
						display:flex;
						flex-direction: row;
						gap: 10px;
					}
					p
					{
						text-align:left;
					}
					a
					{
						display:inline-flex;
						color:#fff;
						padding:9px;
						background-color:transparent;
						border:1px solid #fff;
						text-decoration: none;
						font-size:14px;
					}
					a:hover
					{
						color:#000;
						background-color:#fff;
					}
				}
			}
		}
	}

	.close {
		position:fixed;
		background-color:black;
		top:20px; right: 20px;
		width:100px; height: 100px;
		cursor:pointer;
		transition: transform 200ms ease-in-out;
		&:hover {
			transform: scale(1.1) rotate(90deg);
		}
		.close-inner {
			position:relative;
			&::after,
			&::before {
				position:absolute;
				width: 100px;
				height: 25px;
				content: "";
				top: 38px;
				right: 0;
				transform:rotate(45deg);
				background-color: #fff;
				background-position: center;
			}
			&::before {
				transform:rotate(-45deg);

			}
		}
	}

	.loading {
		display: flex;
		justify-content:center;
		align-items:center;
		height: 100vh;
	}
}

@media screen and (max-width: 900px) {
	.movie-detail-media {
		display:block !important;
		padding: 20px !important;
	}
}

@media screen and (max-width: 450px) {
	.movie-detail-media {
		padding: 0 !important;
	}
	.close {
		top:0!important;right:0!important;
	}
}

@keyframes opacity {
	from {opacity: 0;}
	to {opacity: 1}
}