.search {
	padding: 0 20px;
	position:fixed;
	z-index:5;
	background: black;
    border-radius: 0 0 25px 25px;
	@media screen and (max-width: 600px) {
		width: calc(100% - 40px);
	}
	p {
		margin-top: 0;
	}
	input {
		border: 3px solid white;
		background-color: black;
		color: white;
		outline: none;
		padding: 15px;
		margin: 20px 20px 0;
		font-size: 20px;

		@media screen and (max-width: 520px) {
			display: block;
			margin: 0 0 10px;
			width: 100%;
		}
	}
	h1 {
		display: inline-block;
		padding: 0;
    	margin: 0;
	}
}
