.movie-item {
	flex-basis: 230px;
	
	display: flex;
    flex-direction: column;
	
	

	p {
		color: #fff;
		padding:0;margin:10px 0;
		&.genres {
			font-size: 14px;
			flex-grow: 1;
		}
	}

	img {
		max-width: 100%;
		height: auto;
		cursor:pointer;
		transform:scale(1);
		transition: all 200ms ease-out;
	}

	a.imdb {
		border: 2px solid white;
		color:white;
		padding:10px;
		text-decoration:none;
		display: inline-block;
		&:hover{
            background-color:white;
            color:black;
        }
	}
	.buttons {
		display: flex;
		justify-content: space-between;
	}
	a{
        background-color:transparent;
        font-size:20px;
        border:2px solid red;
        padding:10px;
        color:red;
        cursor: pointer;
		text-decoration:none;
        &:hover{
            background-color:red;
            color:#fff;
        }	
    }
}

@media screen and (max-width: 1300px) {
	.movie-item {
		flex-basis: 25%;
	}
}

@media screen and (max-width: 900px) {
	.movie-item {
		flex-basis: 33%;
	}
	a{
		font-size:14px!important;
	}
	
}

@media screen and (max-width: 450px) {
	.movie-item {
		flex-basis: 50%;
	}
}

@media screen and (max-width: 350px) {
	a{
		font-size:12px!important;
		padding: 6px!important;
	}
}